<template>
  <div :class="cn(['', `--${settings.display}`])">
    <div :class="cn('wrap')">
      <div :class="cn('inner')">
        <a :class="cn('cover')" :href="settings.link_url"> </a>
        <div>
          <img :src="settings.image_url" :alt="settings.image_alt" />
        </div>
        <div :class="cn('caption')">
          <div :class="cn('text')" v-html="settings.caption"></div>
          <a :class="cn('link')" :href="settings.link_url">
            {{ settings.link_title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { bem } from '../../utils/classnames'

const cn = bem('home-section-image')

const props = defineProps<{
  settings: {
    image_alt: string
    image_url: string
    link_title: string
    link_url: string
    display: string
    caption: string
  }
}>()
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';

.home-section-image {
  @include type-xs;
  width: 100%;

  &__wrap {
    position: relative;
  }

  &__inner {
    position: relative;

    @include hover {
      .home-section-image {
        &__link {
          opacity: 1;
        }
      }
    }
  }

  &__cover {
    @include cover;
  }

  &__caption {
    @include type-xs-uppercase;
    justify-content: space-between;
    display: flex;
  }

  &__link {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &--default {
    display: flex;
    justify-items: center;
    padding-top: var(--home-section-padding-large);
    padding-bottom: var(--home-section-padding-large);

    .home-section-image {
      &__wrap {
        position: relative;
        margin: 0 auto;

        @include breakpoint('l+') {
          width: columns(14);
        }

        @include breakpoint('l') {
          width: columns(6);
        }
      }
    }
  }

  &--left,
  &--right {
    @include breakpoint('l+') {
      width: 50%;
      padding-top: var(--home-section-padding-default);
      padding-bottom: var(--home-section-padding-default);
    }
    @include breakpoint('l') {
      width: columns(6);
    }
  }

  &--left {
    .home-section-image {
      @include breakpoint('l+') {
        &__wrap {
          padding-left: calc(var(--inset) + #{columns(2, 0)});
          padding-right: calc(var(--inset) + #{columns(1)});
        }
      }
    }
    @include breakpoint('l') {
      padding-top: var(--home-section-padding-default);
      padding-bottom: var(--home-section-padding-small);
    }
  }

  &--right {
    .home-section-image {
      @include breakpoint('l+') {
        &__wrap {
          padding-right: calc(var(--inset) + #{columns(2, 0)});
          padding-left: calc(var(--inset) + #{columns(1)});
        }
      }
    }
    @include breakpoint('l') {
      padding-top: var(--home-section-padding-small);
      padding-bottom: var(--home-section-padding-default);
    }
  }
}
</style>

<template>
  <div :class="cn()">
    <ShopifyImage :class="cn('image')" :src="product.featured_image.src" ratio="5x7" />
    <a :class="cn('link')" :aria-label="product.title" :href="`/products/${product.handle}`"> </a>
    <div :class="cn('caption')">
      <div :class="cn('number')">{{ (index + 1).toString().padStart(2, '0') }}</div>
      <div :class="cn('heading')">
        <h2 :class="cn('title')" v-html="title"></h2>
        <ProductCardPricesLiquid :class="cn('prices')" :product="product" />
      </div>
      <div :class="cn('cta')">Shop</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LiquidProduct } from '../../types'
import { bem } from '../../utils/classnames'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ProductCardPricesLiquid from '../product/card/ProductCardPricesLiquid.vue'
import { computed } from 'vue'

const cn = bem('home-section-collections-card')

const props = defineProps<{
  index: number
  product: LiquidProduct
}>()

const title = computed(() => props.product.title.replace(/(\(.*$)/, '<span class="variant">$1</span>'))
</script>

<style lang="scss" scoped>
@import '~styles/base';

.home-section-collections-card {
  @include type-xs;
  position: relative;

  @include hover {
    &__prices {
      opacity: 1;
    }
  }

  &__link {
    @include cover;

    @include hover {
      + .home-section-collections-card__caption {
        .home-section-collections-card__prices {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    display: block;
  }

  &__caption {
    @include type-xs-uppercase;
    padding-top: var(--home-gutter-small);
    grid-template-columns: 30px 1fr 50px;
    display: grid;
  }

  &__prices {
    @include transitionLink;
    opacity: 0;
  }

  &__cta {
    @include type-xxs-uppercase;
    text-align: right;
  }
}
</style>

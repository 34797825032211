<template>
  <footer :class="cn()">
    <div :class="cn('inner')">
      <div :class="cn('main')">
        <nav :class="cn('menu')">
          <h3 :class="cn('heading')">Social</h3>
          <ShopifyLinks :class="cn('links')" aria-label="Account navigation" :links="social.links" />
        </nav>
        <nav :class="cn('menu')">
          <h3 :class="cn('heading')">Care</h3>
          <ShopifyLinks
            :class="cn('links')"
            aria-label="Customer Care navigation"
            :links="care.links"
          />
        </nav>
        <ShopifyLinks :class="cn('terms')" aria-label="Terms navigation" :links="terms.links" />
        <nav :class="cn('byline')">
          <a href="https://1of1studio.com" class="shopify-link shopify-link--inactive" target="_blank">
            <span class="shopify-link__text">Site by 1/1</span>
          </a>
          <span :class="cn('copyright')">© J.Hannah Ltd.</span>
        </nav>
      </div>
      <div :class="cn('newsletter')">
        <NewsletterFooter />
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { bem } from '../../utils/classnames'
import { LiquidLinkList } from '../../types/liquid'
import ShopifyLinks from '../shopify/link/ShopifyLinks.vue'
import NewsletterFooter from '../newsletter/NewsletterFooter.vue'
const cn = bem('footer-default')

const props = defineProps<{
  social: LiquidLinkList
  care: LiquidLinkList
  terms: LiquidLinkList
}>()
</script>

<style lang="scss">
@import '~styles/base.scss';

.footer-default {
  &__inner {
    @include type-xs;
    @include wrapper;
    display: flex;
  }

  &__links {
    display: flex;
    flex-direction: column;
    > * {
      margin-right: auto;
    }
  }

  &__heading {
    @include type-xxs-uppercase;
  }

  &__terms,
  &__byline {
    @include type-xxs-uppercase;
    display: flex;
    gap: 16px;
    .shopify-link {
      @include uppercase;
    }
  }

  @include breakpoint('l+') {
    padding-bottom: 16px;

    &__inner {
      justify-content: space-between;
    }

    &__main {
      .shopify-link {
        @include transitionLink;

        @include hover {
          color: var(--color-midgrey);
        }
      }
    }

    &__menu {
      & + & {
        margin-top: 20px;
      }
    }

    &__heading {
      padding-bottom: 4px;
    }

    &__terms {
      margin-top: 20px;
    }

    &__newsletter {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: columns(5);
    }

    &__copyright {
      text-transform: none;
    }
  }
  @include breakpoint('l') {
    // padding-bottom: 60px;
    padding-bottom: 10px;

    &__inner {
      flex-direction: column;
    }

    &__main {
      display: contents;
    }

    &__heading {
      padding-bottom: 8px;
    }

    &__menu {
      & + & {
        margin-top: 16px;
      }
    }

    &__newsletter {
      order: 2;
      margin-top: 340px;
      margin-bottom: 20px;
    }

    &__terms {
      order: 3;
    }

    &__byline {
      order: 3;
    }
  }
}
</style>

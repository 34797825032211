<template>
  <div :class="cn()">
    <div :class="cn('wrap')">
      <HeaderBanner />

      <div :class="cn('inner')">
        <button
          aria-label="menu"
          :class="cn('hamburger')"
          @click="current == 'MOBILE' ? setOverlay('') : setOverlay('MOBILE')"
        >
          <SvgHamburger :active="current == 'MOBILE'" />
        </button>
        <nav :class="cn('logo')">
          <a aria-label="Home" href="/"><SvgLogo /></a>
        </nav>
        <button :class="cn('cart')" aria-label="cart" @click.prevent="setOverlay('CART')">
          ({{ itemCount }})
        </button>
      </div>
    </div>
    <OverlayMobile v-if="current == 'MOBILE'" :main="main" :account="account" />
    <div :class="cn('spacer')"></div>
  </div>
</template>

<script lang="ts" setup>
import SvgLogo from '../svg/SvgLogo.vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import HeaderBanner from './HeaderBanner.vue'
import { useCartInject } from '../../context/useCartContext'
import { bem } from '../../utils/classnames.ts'
import { LiquidLinkList } from '../../types/liquid'
import SvgHamburger from '../svg/SvgHamburger.vue'
import OverlayMobile from '../overlay/OverlayMobile.vue'

defineProps<{
  main: LiquidLinkList
  account: LiquidLinkList
}>()

const cn = bem('header-mobile')

const { setOverlay, current } = useOverlayInject()
const { itemCount } = useCartInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-mobile {
  pointer-events: none;

  &--invert {
    color: var(--color-background);
  }

  @include breakpoint('l+') {
    display: none;
  }

  &__content {
    position: relative;
  }

  &__hamburger,
  &__logo,
  &__cart {
    pointer-events: auto;
  }

  &__spacer {
    height: 0;
  }

  &__wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1010;
    line-height: 30px;
    background: var(--color-background);
  }

  &__inner {
    padding: 12px 20px;
    transition: transform 0.3s ease;
    display: grid;
    grid-template-columns: 20px 1fr 20px;
  }

  &__logo {
    display: flex;
    transition: all 0.15s ease;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }

    a {
      display: inline-flex;
      margin: auto;
      height: auto;
      fill: currentColor;
      transition: fill 0.05s ease;

      svg {
        display: flex;
        width: 100px;
        margin: auto;
      }
    }
  }
  &__hamburger {
  }

  &__cart {
    text-align: right;
    color: currentColor;
    transition: color 0.05s ease;

    span.a {
      color: currentColor;
      transition: color 0.05s ease;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>

<template>
  <OverlayDialogue
    name="overlay-newsletter"
    class="overlay-dialogue--newsletter"
    :path-blacklist="['/collections/nailpolish']"
    :title="title"
  >
    <NewsletterOld :note="note" :image="image" />
  </OverlayDialogue>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import NewsletterOld from '../newsletter/NewsletterOld.vue'
import OverlayDialogue from './OverlayDialogue.vue'

const { shop } = useAppInject()
const newsletter = shop.metafields.newsletter as any
const title = newsletter.title
const note = newsletter.note
const image = newsletter.image[0]
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-dialogue--newsletter {
  bottom: var(--pop-up-offset);
  left: var(--pop-up-offset);

  form {
    width: 100%;
  }
}
</style>

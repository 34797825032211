import { provide, inject, Ref, ref } from 'vue'

export type HeaderContextType = {
  bannerHeight: Ref<number>
  setBannerHeight: any
}

export const HEADER_INJECTION_KEY = Symbol('HEADER')

export const useHeaderContext = () => {
  const bannerHeight = ref(0)

  const values = {
    bannerHeight,
    setBannerHeight: (height: number) => (bannerHeight.value = height),
  }

  provide<HeaderContextType>(HEADER_INJECTION_KEY, values)
  return values
}

export const useHeaderInject = () => {
  const context = inject<HeaderContextType>(HEADER_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}

<template>
  <svg
    @click="toggle"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Hamburger -->
    <g class="hamburger" :class="{ hidden: isActive }">
      <line
        x1="2"
        y1="1"
        x2="10"
        y2="1"
        stroke="var(--color-dark)"
        stroke-width="1"
        stroke-linecap="round"
      />
      <line
        x1="2"
        y1="6"
        x2="10"
        y2="6"
        stroke="var(--color-dark)"
        stroke-width="1"
        stroke-linecap="round"
      />
      <line
        x1="2"
        y1="11"
        x2="10"
        y2="11"
        stroke="var(--color-dark)"
        stroke-width="1"
        stroke-linecap="round"
      />
    </g>
    <!-- Cross (using your provided path) -->
    <g class="cross" :class="{ visible: isActive }" transform="translate(-2,-1)">
      <path
        d="M4.81555 3.18439L4.18198 3.81796L7.38433 7.02031L4.18782 10.2168L4.79876 10.8278L7.99527 7.63125L11.2037 10.8397L11.8373 10.2061L8.62884 6.99768L11.8132 3.81328L11.2023 3.20234L8.0179 6.38674L4.81555 3.18439Z"
        fill="var(--color-dark)"
      />
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = defineProps({
  active: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:active'])
const isActive = ref(props.active)

watch(
  () => props.active,
  (val) => {
    isActive.value = val
  }
)

const toggle = () => {
  isActive.value = !isActive.value
  emit('update:active', isActive.value)
}
</script>

<style scoped>
.hamburger,
.cross {
  transition: opacity 0.3s ease;
}
.hamburger.hidden {
  opacity: 0;
}
.cross {
  opacity: 0;
}
.cross.visible {
  opacity: 1;
}
svg {
  cursor: pointer;
}
</style>

<template>
  <div class="product-card-prices">
    <span v-if="!product.available" class="product-card-prices__sold">Sold Out</span>

    <template v-else-if="product.price_varies">
      <span>From</span>
      <ShopifyPrice :amount="parse(product.price_min)" />
      <span>—</span>
      <ShopifyPrice :amount="parse(product.price_min)" />
    </template>

    <ShopifyPrice class="product-card-prices__price" v-else :amount="parse(product.price_min)" />
    <ShopifyPrice
      class="product-card-prices__compare"
      v-if="product.compare_at_price"
      :amount="parse(product.compare_at_price)"
    />
  </div>
</template>

<script lang="ts" setup>
import { LiquidProduct } from '../../../types'
import ShopifyPrice from '../../shopify/ShopifyPrice.vue'

defineProps<{ product: LiquidProduct }>()

const parse = (value: string) => parseFloat(value) / 100
</script>

<style lang="scss">
@import '~styles/base';

.product-card-prices {
  display: flex;
  vertical-align: baseline;
  transition: var(--transition-opacity);
  gap: 4px;

  &__compare {
    margin-left: 5px;
    text-decoration: line-through;
  }

  @media (min-width: 1400px) {
    text-align: right;
  }

  @include breakpoint('xl') {
    margin-top: 10px;
  }
}
</style>

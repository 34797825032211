<template>
  <div :class="cn([''])">
    <div :class="cn('container')">
      <div :class="cn('inner')">
        <div :class="cn('image')">
          <a :class="cn('cover')" :href="link_url" :aria-label="title"> </a>
          <ShopifyImage :src="image" :alt="title" ratio="5x7" />
        </div>
        <h2 :class="cn('title')">{{ title }}</h2>
        <div :class="cn('caption')" v-html="caption"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ShopifyImage from '../shopify/ShopifyImage.vue'
import { bem } from '../../utils/classnames'

const cn = bem('home-section-feature')

defineProps<{
  image: string
  title: string
  caption: string
  link_url: string
}>()
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';

.home-section-feature {
  padding-top: var(--home-section-padding-default);
  padding-bottom: var(--home-section-padding-default);
  width: 100%;

  &__container {
    @include wrapper;
    display: flex;
    justify-content: center;
  }

  &__inner {
    flex-direction: column;
    text-align: center;
    display: flex;
    position: relative;
  }

  &__cover {
    @include cover;
  }

  &__image {
    position: relative;
    margin: 0 auto 12px;

    @include breakpoint('l+') {
      width: columns(8, -1);
    }

    @include breakpoint('l') {
      width: columns(6, -1);
    }

    @include hover {
      @include transitionLink;
      opacity: 0.8;
    }
  }

  &__title {
    @include display-m-light;
    @include breakpoint('l+') {
      width: columns(18, -1);
    }
  }

  &__caption {
    margin: 0 auto;
    line-height: 14px;
    @include breakpoint('l+') {
      width: columns(10, -1);
    }
  }
}
</style>

<template>
  <span class="shopify-link-submenu" :class="{ focus: hasFocus }" ref="el">
    <span class="shopify-link__text shopify-link-submenu__title">{{ title }}</span>
    <ShopifyLinks :depth="depth + 1" :links="links" class="shopify-link-submenu__submenu" />
  </span>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { LiquidLinkList } from '../../../types/liquid'
import ShopifyLinks from './ShopifyLinks.vue'

const el = ref<HTMLButtonElement | null>(null)
const hasFocus = ref(false)

onMounted(() => {
  const children = el.value?.querySelectorAll('a,button') || []

  for (const child of children) {
    child.addEventListener('focus', () => {
      hasFocus.value = true
    })
    child.addEventListener('blur', () => {
      hasFocus.value = false
    })
  }
})

withDefaults(
  defineProps<{
    depth: number
    links: LiquidLinkList['links']
    title: string
  }>(),
  {
    depth: 0,
  }
)
</script>

<style lang="scss">
@import '~styles/base.scss';

.shopify-link-submenu {
  position: relative;

  &__title {
    display: flex;
    width: columns(2);
    text-align: left;
    cursor: pointer;
  }

  &__submenu {
    // position: absolute;
    // left: 90px;
    // top: 0;
    // opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
    // transform: translateY(5px);
    // transition: opacity 0.2s ease-out, transform 0.1s ease-out;
    // width: 200px;
    background: transparent;
    pointer-events: none;
    .shopify-link .shopify-link__text {
      text-transform: none;
    }
  }

  &.focus & {
    &__submenu {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: auto;
      z-index: 100;
    }
  }

  // @include hover {
  //   .shopify-link-submenu__submenu {
  //     opacity: 1;
  //     transform: translateY(0px);
  //     pointer-events: auto;
  //     z-index: 100;
  //   }
  // }
}
</style>

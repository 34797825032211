// import '~/src/styles/theme.scss'

import { createApp } from 'vue'
import VueScrollTo from 'vue-scrollto'
import { Portal } from 'portal-vue'

import PageAccountActivate from '../components/page/PageAccountActivate.vue'
import PageAccountAddresses from '../components/page/PageAccountAddresses.vue'
import PageAccountDashboard from '../components/page/PageAccountDashboard.vue'
import PageAccountLogin from '../components/page/PageAccountLogin.vue'
import PageAccountOrder from '../components/page/PageAccountOrder.vue'
import PageAccountRegister from '../components/page/PageAccountRegister.vue'
import PageAccountReset from '../components/page/PageAccountReset.vue'
import PageArticleLookbook from '../components/page/PageArticleLookbook.vue'
import PageBlogLookbooks from '../components/page/PageBlogLookbooks.vue'
import PageCart from '../components/page/PageCart.vue'
import PageCollection from '../components/page/PageCollection.vue'
import PageCollectionFeatured from '../components/page/PageCollectionFeatured.vue'
import PageCollectionInStock from '../components/page/PageCollectionInStock.vue'
import PageError from '../components/page/PageError.vue'
import PageProduct from '../components/page/PageProduct.vue'
import PageProductNoSale from '../components/page/PageProductNoSale.vue'
import PageProductPolishSet from '../components/page/PageProductPolishSet.vue'
import PageSearch from '../components/page/PageSearch.vue'
import PageTemplateAbout from '../components/page/PageTemplateAbout.vue'
import PageTemplateColumns from '../components/page/PageTemplateColumns.vue'
import PageTemplateDefault from '../components/page/PageTemplateDefault.vue'
import PageTemplateFaq from '../components/page/PageTemplateFaq.vue'
import PageTemplatePassage from '../components/page/PageTemplatePassage.vue'
import PageTemplatePress from '../components/page/PageTemplatePress.vue'
import PageTemplateSignup from '../components/page/PageTemplateSignup.vue'
import PageTemplateStockists from '../components/page/PageTemplateStockists.vue'
import PageTemplateText from '../components/page/PageTemplateText.vue'

import HomeSectionColors from '../components/home/HomeSectionColors.vue'
import HomeSectionCollections from '../components/home/HomeSectionCollections.vue'
import HomeSectionFeature from '../components/home/HomeSectionFeature.vue'
import HomeSectionImage from '../components/home/HomeSectionImage.vue'
import HomeSectionLinks from '../components/home/HomeSectionLinks.vue'
import HomeSectionSlideshow from '../components/home/HomeSectionSlideshow.vue'

import LayoutDefault from '../components/layout/LayoutDefault.vue'

import HeaderDesktop from '../components/header/HeaderDesktop.vue'
import HeaderMobile from '../components/header/HeaderMobile.vue'
import FooterDefault from '../components/footer/FooterDefault.vue'

const app = createApp({
  components: {
    LayoutDefault,

    HeaderDesktop,
    HeaderMobile,
    FooterDefault,

    HomeSectionColors,
    HomeSectionCollections,
    HomeSectionFeature,
    HomeSectionImage,
    HomeSectionLinks,
    HomeSectionSlideshow,

    PageAccountActivate,
    PageAccountAddresses,
    PageAccountDashboard,
    PageAccountLogin,
    PageAccountOrder,
    PageAccountRegister,
    PageAccountReset,

    PageArticleLookbook,
    PageBlogLookbooks,
    PageCart,
    PageCollection,
    PageCollectionFeatured,
    PageCollectionInStock,
    PageError,
    PageProduct,
    PageProductNoSale,
    PageProductPolishSet,
    PageSearch,

    PageTemplateAbout,
    PageTemplateColumns,
    PageTemplateDefault,
    PageTemplateFaq,
    PageTemplatePassage,
    PageTemplatePress,
    PageTemplateSignup,
    PageTemplateStockists,
    PageTemplateText,
  },

  mounted() {
    const routeScript = document.createElement('script')
    routeScript.setAttribute(
      'src',
      'https://cdn.routeapp.io/route-widget-shopify/route-widget-shopify-1.4.4.min.js?shop=jhannah-dev.myshopify.com'
    )

    // Add Recaptcha Script
    window.addEventListener('DOMContentLoaded', () => {
      const t = document.createElement('script')
      t.setAttribute(
        'src',
        `https://www.recaptcha.net/recaptcha/api.js?render=${window.Shopify?.recaptchaV3?.siteKey}&hl=en`
      ),
        document.body.appendChild(t)
    })
  },
})

// eslint-disable-next-line vue/multi-word-component-names
app.component('Portal', Portal)
app.use(VueScrollTo)

app.mount('#app')

<template>
  <div class="polish-set-heading">
    <h1 class="polish-set-heading__title">Polish: Create your own 3 set</h1>
    <div class="polish-set-heading__amount">
      <div class="polish-set-heading__price">
        <h2 class="polish-set-heading__subtitle">Price</h2>
        <ShopifyPrice :amount="parseFloat(product.price_max) / 100" />
      </div>
      <div class="polish-set-heading__affirm">
        <div
          class="affirm-as-low-as"
          :data-amount="product.price_max"
          data-affirm-type="text"
          data-page-type="product"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { usePolishSetInject } from '../../context/usePolishSetContext'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'

const { product } = usePolishSetInject()

onMounted(() => {
  window?.affirm.ui.refresh()
})
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/legacy.scss';

.polish-set-heading {
  display: grid;
  grid-template-columns: var(--columns);
  gap: var(--gutter);

  &__title {
    @include heading-l-serif();
    grid-column: 2 / 3;
    max-width: 200px;
    margin-bottom: 80px;

    @include breakpoint('l') {
      margin-bottom: 0;
    }
  }

  &__amount {
    align-self: end;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    margin-bottom: 80px;

    @include breakpoint('l') {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  &__affirm {
    font-size: 12px;
    color: var(--color-midgrey);

    @include breakpoint('l') {
      margin-top: 10px;
    }

    a {
      text-decoration: underline;
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    }
  }

  @include breakpoint('l') {
    display: flex;
    margin-bottom: 40px;

    &__title,
    &__price {
      margin-bottom: 0;
    }

    &__price {
      align-self: initial;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: inherit;
    }
  }
}
</style>

<template>
  <button
    @click.prevent="onClick"
    class="tab-feature-button"
    :class="{ 'tab-feature-button--active': active }"
  >
    <span class="tab-feature-button__background" />
    <slot />
  </button>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    active?: boolean
    tag?: string
  }>(),
  { active: false, tag: 'button' }
)

const emits = defineEmits(['onClick'])

const onClick = () => {
  emits('onClick')
}
</script>

<style lang="scss">
@import '~styles/base';

.tab-feature-button {
  font-family: 'folio', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  font-size: 12px;
  line-height: 1.45;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 4px;
  line-height: 1;
  transition: color 150ms ease-out;

  @include hover {
    .tab-feature-button__background {
      height: calc(100% + 3px);
    }
  }

  &--active & {
    &__background {
      border-bottom-color: var(--color-background);
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--color-background);
    border: 1px solid var(--color-dark);
    transition: height 0.175s ease;
  }
}
</style>

<template>
  <div :class="cn('')">
    <h2 :class="cn('heading')">{{ heading }}</h2>
    <nav :class="cn('buttons')">
      <button
        aria-label="Select Color"
        v-for="(_title, mediaIndex) in titles"
        :key="`media_${mediaIndex}`"
        @click.prevent="() => onSelectItem(mediaIndex)"
        @mouseenter.prevent="() => onSelectItem(mediaIndex)"
        :class="cn(['button', `button--${mediaIndex == index ? 'active' : 'inactive'}`])"
        :data-letter="String.fromCharCode(65 + mediaIndex)"
      >
        {{ _title }}
      </button>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { useSlideshowInject } from '../../context/useSlideshowContext'
import { bem } from '../../utils/classnames'

const cn = bem('home-section-colors-list')
const { emblaApi, emblaPaginationApi, index } = useSlideshowInject()

defineProps<{ heading: string; titles: string[] }>()

const onSelectItem = (index: number) => {
  if (emblaApi?.value) {
    emblaApi.value.scrollTo(index)
    if (emblaPaginationApi?.value) {
      emblaPaginationApi.value.scrollTo(index)
    }
  }
}
</script>

<style lang="scss">
@import '~styles/base';

.home-section-colors-list {
  gap: var(--home-gutter-small);

  &__heading {
    @include type-xs-uppercase;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    padding-top: var(--home-gutter-small);
    gap: var(--home-gutter-small);

    @include breakpoint('l') {
      padding-top: 10px;
      gap: 10px;
    }
  }

  &__button {
    -webkit-font-smoothing: antialiased;
    position: relative;
    padding-left: 28px; /* Make space for the prefix letter */

    &:before {
      content: '(' attr(data-letter) ')';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &--inactive {
      color: var(--color-midgrey);
    }
  }
}
</style>

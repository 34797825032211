<template>
  <span :class="{ 'shopify-link--active': isActive }">
    <button
      v-if="!isActive"
      role="button"
      class="shopify-link-search__title shopify-link__text"
      @click.prevent="isActive = !isActive"
    >
      SEARCH
    </button>

    <form
      @submit.prevent="onSearchSubmit"
      class="shopify-link-search__form"
      v-if="isActive"
      action="/search"
      v-cloak
    >
      <input type="hidden" name="type" value="product" />
      <input
        class="shopify-link-search__input"
        type="text"
        name="q"
        placeholder=""
        required
        ref="searchInput"
      />
      <button aria-label="Search" type="submit">
        <SvgSearchIcon />
      </button>
    </form>
  </span>
</template>

<script lang="ts" setup>
import { encodeFormData } from '_library'
import { ref, watch } from 'vue'
import SvgSearchIcon from '../../svg/SvgSearchIcon.vue'

const isActive = ref(false)
const searchInput = ref<HTMLInputElement | null>(null)

withDefaults(
  defineProps<{
    depth: number
  }>(),
  {
    depth: 0,
  }
)

const onSearchSubmit = (e: any) => {
  const params = encodeFormData(e.target)
  const query = `/search?${params}&-tag:search-exclude`

  window.location.href = query
}

watch(isActive, (newIsActive) => {
  if (newIsActive) {
    setTimeout(() => {
      if (searchInput.value) {
        searchInput.value?.focus()
      }
    }, 100)
  }
})
</script>

<style lang="scss">
@import '~styles/base';

.shopify-link-search {
  &__form {
    + :after {
      opacity: 1;
    }
  }

  &__input {
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s ease;
  }
}
.shopify-link--search.shopify-link--active {
  .shopify-link-search__input {
    // border-color: var(--color-dark);
  }
}
</style>

import { provide, inject, Ref, ref } from 'vue'

export type DropdownContextProps = {
  initial?: string
}

export type DropdownContextType = {
  current?: Ref<string>
  toggle: any
  set: any
}

export const DROPDOWN_INJECTION_KEY = Symbol('DROPDOWN')

export const useDropdownContext = (options?: DropdownContextProps) => {
  const current = ref(options?.initial || '')

  const set = (index: string) => {
    current.value = index
  }

  const toggle = (index: string) => {
    if (index == current.value) {
      current.value = ''
    } else {
      current.value = index
    }
  }

  const values = {
    set,
    toggle,
    current,
  }

  provide<DropdownContextType>(DROPDOWN_INJECTION_KEY, values)
  return values
}

export const useDropdownInject = () => {
  const context = inject<DropdownContextType>(DROPDOWN_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}

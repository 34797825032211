<template>
  <CommonContainer class="page-template-faq" :title="page.title">
    <div class="page-template-faq__questions">
      <Togglable
        v-for="block in blocks"
        :key="block.slug"
        :name="block.slug"
        :active-sibling="activeBlock"
        @toggle="handleBlockToggle"
      >
        <template #button>
          <h2 class="block-heading" :ref="block.slug">
            {{ block.heading }}
          </h2>
        </template>
        <div class="block-body" v-html="block.body"></div>
      </Togglable>
    </div>
  </CommonContainer>
</template>

<script lang="ts" setup="">
import slugify from '@sindresorhus/slugify'
import { computed, onMounted, ref } from 'vue'
import { sortNamespace } from '_library'
import { LiquidPage } from '../../types/liquid'
import Togglable from '../Togglable.vue'
import CommonContainer from '../common/CommonContainer.vue'

const props = defineProps<{
  page: LiquidPage
}>()

const activeBlock = ref('')

const blocks = computed(() =>
  sortNamespace(props.page.metafields.faq).map((block: any) => ({
    ...block,
    slug: slugify(block.heading),
  }))
)

const handleBlockToggle = (block: any) => {
  if (block.active) {
    activeBlock.value = block.name
  }
}

onMounted(() => {
  const slug = window.location.hash.replace(/^#/, '')
  const matchingBlock = blocks.value.find((block) => block.slug === slug)

  if (matchingBlock) {
    activeBlock.value = slug

    const top = (document?.getElementById(`block_${slug}`)?.offsetTop || 0) + 300

    window.scrollTo({
      top,
    })
  }
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-template-faq {
  width: 100%;

  .page-template-container__heading {
    text-align: center;
    h1 {
      @include heading-l-serif;
    }
  }

  h2 a {
    text-decoration: none;
  }

  &__questions {
    max-width: 650px;
    margin: auto;
    padding: 0 25px;
  }

  .block-heading {
    @include serif;
    @include type-l;
    line-height: 1.1;
    text-align: left;
    transition: color 0.3s ease;
  }

  .togglable {
    padding: 10px 0 5px;
    border-top: 1px solid var(--color-lightgrey);
    &:last-child {
      border-bottom: 1px solid var(--color-lightgrey);
    }
    &.active {
      .toggle-button:after {
        content: '-';
      }
    }
  }

  .toggle-button {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &:after {
      content: '+';
      font-size: 13px;
    }
    &:focus,
    &:hover {
      .block-heading {
        color: var(--color-lightgrey);
      }
    }
  }

  .toggle-content {
    @include content;
    @include helper-link-underline(false);
    padding: 20px 0;
    h3 {
      @include uppercase;
      ~ h3 {
        margin-top: 20px;
      }
    }
  }
}
</style>

<template>
  <div class="home-section-controls">
    <button
      @click="onPrev"
      class="home-section-controls__button home-section-controls__button--prev"
      v-if="ifPrev"
    >
      ←
    </button>
    <button
      @click="onNext"
      class="home-section-controls__button home-section-controls__button--next"
      v-if="ifNext"
    >
      →
    </button>
    <button
      @click="onNext"
      class="home-section-controls__button home-section-controls__button--cover"
    ></button>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'
import { useSlideshowInject } from '../../context/useSlideshowContext'

const { emblaApi, index, total } = useSlideshowInject()

const onPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev()
  }
}

const onNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext()
  }
}

const ifPrev = computed(() => unref(index) > 0)
const ifNext = computed(() => unref(index) < unref(total) - 1)
</script>

<style lang="scss">
@import '~styles/base';

$cn: 'home-section-controls';

.#{$cn} {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;

  &:focus,
  &:hover {
    .#{$cn}__button {
      // opacity: 1;
    }
  }

  &__button {
    position: absolute;
    transition: opacity 0.3s ease;
    padding: var(--gutter);

    @include breakpoint('l+') {
      opacity: 0;

      &.#{$cn}__button--cover {
        pointer-events: auto;
      }
    }

    @include breakpoint('l') {
      // pointer-events: auto;
    }

    &--cover {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 0;
    }

    &--prev,
    &--next {
      display: none;
      top: 50%;
      transform: translateY(-50%);
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
</style>

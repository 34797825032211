<template>
  <div class="DisplayContent">
    <slot><SanityBlocks :blocks="blocks" /></slot>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'
import SanityBlocks from './SanityBlocks.vue'

const props = withDefaults(
  defineProps<{
    blocks: Array<any>
  }>(),
  { blocks: () => [] }
)

const { blocks } = toRefs(props)
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/svg.scss';

.DisplayContent {
  @include heading;

  --counter-width: 25px;

  ol {
    counter-reset: num;

    li {
      position: relative;
      counter-increment: num;
      padding-left: calc(var(--counter-width) + 5px);

      &:before {
        content: counter(num);
        position: absolute;
        left: 0;
        top: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--counter-width);
        height: var(--counter-width);
        font-size: 0.525em;
        font-weight: bold;
        background-image: $svg-circle-outline;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  @include breakpoint('xs') {
    --counter-width: 18px;

    font-size: 20px;
  }
}
</style>

<template>
  <div class="stockists-component">
    <aside class="legend">
      <div class="key">
        <h3 class="key-heading">Key</h3>
        <ul class="key-list">
          <li class="key-item"><span class="badge badge-jewelry">J</span> Jewelry</li>
          <li class="key-item"><span class="badge badge-polish">P</span> Polish</li>
          <li class="key-item"><span class="badge badge-lamps">L</span> Lamps</li>
        </ul>
      </div>
    </aside>
    <div class="regions">
      <section v-for="region in groupByLocation(stockists, 'region')" :key="region.name" class="region">
        <h2 class="region-name">
          {{ region.name }}
        </h2>
        <StockistRow
          v-for="(subregionChunk, chunkIndex) in chunkSubregion(
            groupByLocation(region.sublocations, 'subregion')
          )"
          :key="region.name + chunkIndex"
          :is-active-region="region.name === activeRegion"
          :active-region="activeRegion"
          :active-subregion="activeSubregion"
          :chunk="subregionChunk"
          :chunk-size="chunkSize"
          @toggle-subregion="toggleSubregion($event, region.name)"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import StockistRow from './StockistRow.vue'
import { useWindowSize } from '@vueuse/core'
import { ref, computed } from 'vue'

const props = defineProps<{
  stockists: any[]
}>()

const windowWidth = useWindowSize().width
const activeRegion = ref('')
const activeSubregion = ref('')

const chunkSize = computed(() => {
  if (windowWidth.value < 550) return 2
  if (windowWidth.value < 650) return 3
  if (windowWidth.value < 800) return 4
  if (windowWidth.value < 1400) return 5
  return 6
})

function groupByLocation(stockists: any, location: any) {
  const locationObject = _.groupBy(stockists, location)
  const locationData = Object.keys(locationObject).map((l) => ({
    name: l,
    sublocations: locationObject[l],
  }))
  return locationData
}

function chunkSubregion(subregions: any) {
  return _.chunk(subregions, chunkSize.value)
}

function toggleSubregion(subRegion: any, regionName: any) {
  activeSubregion.value = subRegion
  activeRegion.value = !!subRegion ? regionName : ''
}

const regionGroups = computed(() => {
  return groupByLocation(props.stockists, 'region')
})
</script>

<template>
  <div :class="cn()">
    <div :class="cn('inner')">
      <ShopifyLinks :class="cn('menu')" aria-label="Main navigation" :links="main.links" />
      <ShopifyLinks
        :class="[cn('menu'), cn('menu--account')]"
        aria-label="Account navigation"
        :links="account.links"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LiquidLinkList } from '../../types'
import { bem } from '../../utils/classnames.ts'
import ShopifyLinks from '../shopify/link/ShopifyLinks.vue'

defineProps<{
  main: LiquidLinkList
  account: LiquidLinkList
}>()

const cn = bem('header-overlay')
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--color-background);
  z-index: 1000;
  padding-top: 128px;
  pointer-events: auto;

  &__inner {
    @include wrapper;
  }

  &__menu {
    position: relative;

    & + & {
      padding-top: 128px;
    }

    &--account {
      @include type-xs-uppercase;
    }
  }

  .shopify-links {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .shopify-link--search {
    @include type-xs;
  }

  .shopify-link-dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gutter);

    &--active {
      .shopify-link-dropdown {
        &__title {
          text-decoration: underline;
        }
      }
    }

    &__title {
      @include type-xs-uppercase;
    }

    &__links {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(50% - var(--gutter) / 2);
      padding-left: var(--gutter);
      background: var(--color-background);

      .shopify-link {
        &--heading {
          @include type-xs-uppercase;
          color: var(--color-dark);
          margin-top: 16px;

          @include hover {
            &:after {
              display: none;
            }
          }
        }

        + *:not(.shopify-link--heading) {
          margin-top: 2px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .shopify-link--currencies {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gutter);
  }
}
</style>

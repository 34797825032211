<template>
  <div :class="cn([''])">
    <div :class="cn('container')">
      <div :class="cn('heading')">
        <h2 :class="cn('title')">{{ settings.title }}</h2>
        <div :class="cn('introduction')" v-html="settings.caption"></div>
      </div>
      <div :class="cn('inner')">
        <div :class="cn('slideshow')">
          <CommonSlideshow mode="FADE" :slider-config="{ containScroll: 'keepSnaps', align: 'start' }">
            <div :class="cn('slide')" v-for="(slide, index) in slides" :key="index">
              <img :class="cn('image')" :src="slide.image" :alt="slide.image_alt" />
            </div>
            <template #after>
              <div :class="cn('caption')" v-html="settings.caption"></div>
              <HomeSectionColorsList
                :heading="settings.heading"
                :titles="slides.map((slide) => slide.title)"
              />
            </template>
          </CommonSlideshow>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CommonSlideshow from '../common/CommonSlideshow.vue'
import { bem } from '../../utils/classnames'
import HomeSectionColorsList from './HomeSectionColorsList.vue'

const cn = bem('home-section-colors')

defineProps<{
  settings: {
    title: string
    caption: string
    heading: string
  }
  slides: {
    image: string
    image_alt: string
    title: string
  }[]
}>()
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-section-colors {
  padding-top: var(--home-section-padding-default);
  padding-bottom: var(--home-section-padding-default);
  width: 100%;

  &__container {
    @include wrapper;
  }

  &__heading {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include display-m-light;
  }

  &__introduction {
    margin: 0 auto;
    line-height: 14px;
    @include breakpoint('l+') {
      width: columns(12, -1);
    }
    @include breakpoint('l') {
      display: none;
    }
  }

  &__caption {
    @include breakpoint('l+') {
      display: none;
    }
  }

  .common-slideshow {
    display: flex;
    margin-top: 12px;
    margin-right: auto;
    gap: var(--gutter);

    @include breakpoint('l+') {
      margin-left: columns(8, 0);
      &__slider {
        width: columns(8, -1);
      }
      &__after {
        width: columns(8, -1);
      }
    }
    @include breakpoint('l') {
      flex-direction: column;
      &__after {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: var(--gutter);
      }
    }
  }
}
</style>

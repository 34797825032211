<template>
  <div class="overlay-cart-note">
    <button v-if="!showNote && !note" @click.prevent="toggleShowNote">Add note</button>
    <form class="input" v-else @submit.prevent="onSubmit">
      <input placeholder="Order Notes" type="text" v-model="note" />
      <button type="submit">Save</button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useCartInject } from '../../../context/useCartContext'

const { cart, updateCart } = useCartInject()

const note = ref(cart.value.note)
const showNote = ref(!!cart.value.note)

const toggleShowNote = () => {
  showNote.value = !showNote.value
}

const onSubmit = async () => {
  await updateCart({
    note: note.value,
  })
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart-note {
  position: relative;
  padding: 10px 10px 0;
  background: var(--color-lightbackground);

  button {
    @include type-s;

    left: 10px;
    top: 10px;
    line-height: 20px;
    padding: 0;
  }

  > button {
    border-bottom: 1px solid var(--color-dark);
  }

  .input {
    position: relative;

    input {
      @include type-s;

      left: 15px;
      right: 15px;
      top: 0;
      height: 20px;
      border-bottom: 1px solid var(--color-lightgrey);
    }

    button {
      top: 0;
      right: 15px;
      left: auto;
    }
  }
}
</style>

<template>
  <div :class="cn([''])">
    <div :class="cn('inner')">
      <div :class="cn('items')">
        <div
          :class="
            cn([
              'item',
              `item--${
                selectedIndex == -1 ? 'default' : index == selectedIndex ? 'active' : 'inactive'
              }`,
            ])
          "
          v-for="(block, index) in blocks"
          :key="index"
        >
          <a
            :class="cn(['link'])"
            :href="block.link_url"
            @mouseover="() => (selectedIndex = index)"
            @mouseleave="() => (selectedIndex = -1)"
          >
            {{ block.link_title }}
          </a>
          <ShopifyImage :class="cn(['image'])" :src="block.image_url" :alt="block.image_alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { bem } from '../../utils/classnames'
import ShopifyImage from '../shopify/ShopifyImage.vue'

const cn = bem('home-section-links')
const selectedIndex = ref(-1)

defineProps<{
  blocks: {
    image_url: string
    image_alt: string
    link_title: string
    link_url: string
  }[]
}>()
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-section-links {
  padding-top: var(--home-section-padding-default);
  padding-bottom: var(--home-section-padding-default);
  width: 100%;

  @include breakpoint('l') {
    display: none;
  }

  &__inner {
    @include breakpoint('l+') {
      width: columns(20);
      margin: 0 auto;
    }

    @include breakpoint('l') {
      @include wrapper;
    }
  }

  &__items {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    @include display-xxl-light;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__item {
    display: inline-flex;

    &:not(:last-child) {
      .home-section-links__link:after {
        position: relative;
        z-index: 10;
        content: ',';
        display: inline-flex;
        margin-right: 5px;
        margin-left: -5px;
      }
    }

    &--default {
      .home-section-links {
        &__image {
          opacity: 0;
        }
      }
    }

    &--active {
      .home-section-links {
        &__link,
        &__image {
          opacity: 1;
        }
      }
    }

    &--inactive {
      .home-section-links {
        &__link {
          opacity: 0;
        }
        &__image {
          opacity: 0;
        }
      }
    }
  }

  &__link {
    transition: all 0.4s ease;
    position: relative;
    z-index: 10;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease;
    display: block;
    object-fit: contain;
    object-position: center;

    &--inactive {
      opacity: 0;
    }
  }
}
</style>

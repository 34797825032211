<template>
  <div :class="cn()">
    <div :class="cn('inner')">
      <HomeSectionCollectionsMenu
        :class="cn('menu')"
        :selectBlockIndex="selectBlockIndex"
        :selectedBlock="selectedBlock"
        :blocks="blocks"
      />
      <Swiper
        ref="carousel"
        @swiper="onSwiper"
        v-bind="{ ...swiperOptions }"
        :auto-update="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="false"
        :class="cn('swiper')"
      >
        <SwiperSlide :class="cn('menuSlide')">
          <HomeSectionCollectionsMenu
            :selectBlockIndex="selectBlockIndex"
            :selectedBlock="selectedBlock"
            :blocks="blocks"
          />
        </SwiperSlide>

        <SwiperSlide
          v-for="(product, k) in selectedBlock.products"
          :key="`global_carousel_${k}`"
          :class="cn('slide')"
        >
          <HomeSectionCollectionsCard :index="k" :product="product" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { LiquidCollection, LiquidProduct } from '../../types'
import { bem } from '../../utils/classnames'
import { SwiperOptions } from 'swiper/types'
import { Swiper, SwiperSlide } from 'swiper/vue'
import HomeSectionCollectionsCard from './HomeSectionCollectionsCard.vue'
import HomeSectionCollectionsMenu from './HomeSectionCollectionsMenu.vue'

const cn = bem('home-section-collections')

export type HomeSectionCollectionsBlock = {
  collection: LiquidCollection
  products: LiquidProduct[]
}

const props = defineProps<{
  blocks: HomeSectionCollectionsBlock[]
}>()

const selectedBlockIndex = ref(0)
const selectBlockIndex = (index: number) => (selectedBlockIndex.value = index)
const selectedBlock = computed(() => props.blocks[selectedBlockIndex.value])

const swiperOptions = {
  slidesPerView: 'auto' as const,
  allowTouchMove: true,
  simulateTouch: true,
  grabCursor: true,
  mousewheel: true,
} as SwiperOptions

const swiperRef = ref(null)
const onSwiper = (swiper: any) => {
  swiperRef.value = swiper
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-section-collections {
  padding-top: var(--home-section-padding-default);
  padding-bottom: var(--home-section-padding-default);
  width: 100%;

  &__inner {
    @include breakpoint('l') {
      @include wrapper;
      flex-direction: column;
      display: flex;
      gap: 40px;
    }
  }

  &__menu {
    position: relative;
    z-index: 5;
    @include breakpoint('l+') {
      display: none;
    }
  }

  &__menuSlide {
    @include breakpoint('l+') {
      width: calc(var(--inset) + columns(9, 0));
    }
    @include breakpoint('l') {
      display: none;
    }
  }

  &__swiper {
    width: 100%;
    overflow: hidden;
  }

  .swiper-wrapper {
    width: fit-content;
    @include breakpoint('l') {
      width: 100%;
    }
  }

  .swiper-slide {
    @include breakpoint('l+') {
      &:not(:first-child) {
        width: columns(4, -1);
      }
      &:last-child {
        margin-right: var(--inset);
      }
      &:not(:first-child):not(:last-child) {
        margin-right: columns(2, 1);
      }
    }

    @include breakpoint('l') {
      &:not(.home-section-collections__menuSlide) {
        width: columns(4, -0.5);
      }

      &:not(:last-child) {
        margin-right: var(--gutter);
      }
    }
  }
}
</style>

<template>
  <div :class="cn()">
    <div :class="cn('heading')">
      <h4>J.H NEWS</h4>
      <span>By selecting 'submit' you agree to our Privacy Policy and Terms and Conditions</span>
    </div>

    <form method="get" :class="cn('form')" @submit.prevent="submit">
      <input
        :class="cn('input')"
        placeholder="Email Address"
        aria-label="Email address"
        type="text"
        v-model="email"
        required
      />
      <button :class="cn('submit')">Submit</button>
    </form>

    <TransitionHeight>
      <div v-if="message" :class="cn('message')">
        {{ message }}
      </div>
    </TransitionHeight>
  </div>
</template>

<script lang="ts" setup>
import TransitionHeight from '../transition/TransitionHeight.vue'
import { subscribe } from 'klaviyo-subscribe'
import { bem } from '../../utils/classnames'
import { ref } from 'vue'

const cn = bem('newsletter-footer')

const props = withDefaults(
  defineProps<{
    listId?: string
  }>(),
  {
    listId: 'M7BKjA',
  }
)

const email = ref('')
const message = ref('')

const submit = async () => {
  try {
    const response = await subscribe(props.listId, email.value)
    if (response.success) {
      message.value = 'Thanks for signing up!'
      email.value = ''
    } else {
      throw new Error(response.errors[0])
    }
  } catch (exception: any) {
    message.value = `${exception.message}`
  }
}
</script>

<style lang="scss">
@import '~styles/base';

.newsletter-footer {
  @include type-xxs;

  &__heading {
    margin-bottom: 8px;
    h4 {
      float: left;
      margin-right: 20px;
    }
    span {
      color: var(--color-midgrey);
    }
  }

  &__form {
    display: flex;
  }

  &__input {
    @include type-xs;
    background: var(--color-offwhite);
    flex-grow: 1;
    padding: 0 8px;

    &::placeholder {
      color: var(--color-midgrey);
    }
  }

  &__submit {
    @include uppercase;
    padding: 6px 8px 4px;

    border: 1px solid var(--color-dark);
    display: inline-flex;
    text-align: center;
    transition: all 0.2s ease;

    &,
    &:active {
      background: var(--color-dark);
      color: var(--color-background);
    }

    @include hover {
      background: var(--color-background);
      color: var(--color-dark);
    }
  }

  &__message {
    padding-top: 8px;
  }
}
</style>

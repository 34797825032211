<template>
  <div class="common-slideshow">
    <div class="common-slideshow__slider" ref="emblaRef">
      <div class="common-slideshow__track">
        <slot name="default" :slider-api="emblaApi" :pagination-api="emblaPaginationApi"></slot>
      </div>
    </div>

    <div class="common-slideshow__pagination" v-if="slots['pagination']">
      <div class="common-slideshow__pagination__slider" ref="emblaPaginationRef">
        <div class="common-slideshow__pagination__track">
          <slot name="pagination" :slider-api="emblaApi" :pagination-api="emblaPaginationApi"></slot>
        </div>
      </div>
    </div>

    <div class="common-slideshow__after" v-if="slots['after']">
      <slot name="after" :slider-api="emblaApi" :pagination-api="emblaPaginationApi"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { EmblaOptionsType } from 'embla-carousel'
import { SlideshowContextMode, useSlideshowContext } from '../../context/useSlideshowContext'

const slots = useSlots()

const props = withDefaults(
  defineProps<{
    mode: SlideshowContextMode
    sliderConfig?: EmblaOptionsType
    thumbnailConfig?: EmblaOptionsType
  }>(),
  {
    mode: 'DEFAULT' as const,
    sliderConfig: undefined,
    thumbnailConfig: undefined,
  }
)

const { emblaRef, emblaPaginationRef, emblaPaginationApi, emblaApi } = useSlideshowContext({
  mode: props.mode,
  sliderConfig: props.sliderConfig,
  thumbnailConfig: props.thumbnailConfig,
})
</script>

<style lang="scss">
.common-slideshow {
  &__slider {
    overflow: hidden;
  }

  &__track {
    display: flex;

    > * {
      flex: 0 0 100%;
      min-width: 0;
    }
  }

  &__pagination {
    /* overflow: hidden; */

    &__track {
      display: flex;

      > * {
        flex: 0 0 100%;
        min-width: 0;
      }
    }
  }
}
</style>

<template>
  <seciton class="page-error">
    <section class="page-error__heading">
      <h1>404</h1>
    </section>

    <section class="page-error__content">
      <div class="page-error__description">
        <p>
          Sorry we can't find that page, visit the shop here<br />
          or contact us
          <a href="mailto:inquiries@jhannahjewelry.com" target="_blank">inquiries@jhannahjewelry.com</a>
        </p>
      </div>
    </section>
  </seciton>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '~styles/base';

.page-error {
  &__heading {
    @include type-xl;
    text-align: center;
    padding-bottom: 20px;
  }

  &__description {
    text-align: center;
    a {
      text-decoration: underline;
      @include hover {
        text-decoration: none;
      }
    }
  }
}
</style>

<template>
  <div :class="cn()" ref="menuRef">
    <div :class="cn(['title', `title--${isTitleFaded ? 'faded' : 'opaque'}`])" ref="titleRef">
      Featured
    </div>
    <div :class="cn('buttons')">
      <button
        :class="
          cn([
            'button',
            `button--${
              selectedBlock.collection.handle == block.collection.handle ? 'active' : 'inactive'
            }`,
          ])
        "
        v-for="(block, index) in blocks"
        :key="`home-section-collections_${index}`"
        @click="selectBlockIndex(index)"
      >
        {{ block.collection.title }} <span>( {{ block.collection.products_count }} )</span>
      </button>
      <div :class="cn('link')">
        <a :href="`/collections/${selectedBlock.collection.handle}`">Shop All</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { bem } from '../../utils/classnames'
import { HomeSectionCollectionsBlock } from './HomeSectionCollections.vue'

const cn = bem('home-section-collections-menu')
const props = defineProps<{
  selectBlockIndex: any
  selectedBlock: HomeSectionCollectionsBlock
  blocks: HomeSectionCollectionsBlock[]
}>()

const menuRef = ref<HTMLElement | null>(null)
const titleRef = ref<HTMLElement | null>(null)
const isTitleFaded = ref(false)
const isDesktop = ref(false)

const checkBreakpoint = () => {
  isDesktop.value = window.innerWidth >= 1024
}

// Handle scroll event
const handleScroll = () => {
  if (!isDesktop.value || !titleRef.value) return

  const titleRect = titleRef.value.getBoundingClientRect()
  const windowHeight = window.innerHeight

  const fadeThreshold = windowHeight * 0.7

  isTitleFaded.value = titleRect.bottom < fadeThreshold
}

onMounted(() => {
  checkBreakpoint()
  window.addEventListener('resize', checkBreakpoint)
  window.addEventListener('scroll', handleScroll)

  // Initial check
  handleScroll()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkBreakpoint)
  window.removeEventListener('scroll', handleScroll)
})
</script>
<style lang="scss">
@import '~styles/base.scss';
.home-section-collections-menu {
  gap: var(--home-gutter-small);
  display: grid;

  @include breakpoint('l+') {
    margin-left: var(--inset);
    width: columns(9);
    grid-template-columns: #{columns(3)} #{columns(6)};
  }

  @include breakpoint('l') {
    gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
  }

  &__title {
    @include type-xs-uppercase;
    transition: opacity 0.3s ease;

    &--faded {
      @include breakpoint('l+') {
        opacity: 0;
      }
    }
  }

  &__buttons {
    @include type-xs-uppercase;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    gap: var(--home-gutter-small);
  }

  &__button {
    position: relative;
    color: var(--color-midgrey);
    &,
    span {
      @include transitionLink;
    }
    span {
      opacity: 0;
    }
    &:hover,
    &--active {
      color: var(--color-foreground);
      span {
        opacity: 1;
      }
    }
  }
  &__link {
    margin-top: 20px;
    a {
      @include type-xxs-uppercase;
      @include transitionLink;
      @include hover {
        color: var(--color-midgrey);
      }
    }
  }
}
</style>

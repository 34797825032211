<template>
  <div
    :class="cn(['', `--${display}`, `--${!!currentSlide.subtitle ? 'with-subtitle' : 'no-subtitle'}`])"
    v-if="slides && slides.length"
  >
    <a
      v-if="currentSlide.link_url"
      :key="'link-' + currentSlideIndex"
      :class="cn('cover')"
      :href="currentSlide.link_url"
    >
    </a>
    <div :class="cn('pager')">{{ currentSlideIndex + 1 }} / {{ totalSlides }}</div>
    <div :class="cn('inner')">
      <div
        v-if="currentSlide"
        :key="'caption-' + currentSlideIndex"
        :class="cn('title')"
        v-html="currentSlide.title"
      ></div>
      <a
        v-if="currentSlide.link_url"
        :key="'link-' + currentSlideIndex"
        :class="cn('link')"
        :href="currentSlide.link_url"
      >
        {{ currentSlide.link_title }}
      </a>
    </div>
    <div
      v-if="currentSlide.subtitle"
      :key="'caption-' + currentSlideIndex"
      :class="cn('subtitle')"
      v-html="currentSlide.subtitle"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useSlideshowInject } from '../../context/useSlideshowContext'
import { bem } from '../../utils/classnames'
import { HomeSectionSlideshowSlide } from './HomeSectionSlideshow.vue'

const cn = bem('home-section-slideshow-caption')

const props = defineProps<{
  slides: HomeSectionSlideshowSlide[]
  display: string
}>()

// Use the slideshow context (provided by CommonSlideshow) to get slider state.
const { index: currentSlideIndex, total } = useSlideshowInject()

const totalSlides = computed(() => total.value || props.slides.length)
const currentSlide = computed(() => props.slides[currentSlideIndex.value])
</script>

<style lang="scss">
@import '~styles/base.scss';

$cn: 'home-section-slideshow-caption';

.#{$cn} {
  display: grid;
  grid-template-columns: columns(1) 1fr;
  align-items: center;
  gap: 0 8px;
  position: relative;
  background: transparent;
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;

  &--with-subtitle {
    .#{$cn} {
      &__title {
        @include type-xs-uppercase;
      }
      &__link {
        @include transitionLink;
        opacity: 0;
      }
    }

    @include hover {
      .#{$cn}__link {
        opacity: 1;
      }
    }
  }

  &--no-subtitle {
    .#{$cn}__link {
      @include transitionLink;
    }

    @include hover {
      .#{$cn}__link {
        color: var(--color-midgrey);
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__pager {
    min-width: 48px;
    margin-bottom: auto;
  }

  &__title {
    flex: 1;
  }

  &__link {
    text-align: right;
    @include transitionLink;

    @include hover {
      color: var(--color-midgrey);
    }
  }

  &__cover {
    @include cover;
  }

  &__subtitle {
    grid-column: 1 / -1;
  }
}
</style>

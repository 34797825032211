<template>
  <AccountForms title="Create Account">
    <FormRegister />
    <div>
      <FormLogin v-if="mode === 'Login'" />
      <FormForgot v-else-if="mode === 'Forgot'" />
      <div class="page-account-register__switches">
        <button class="page-account-register__switch" @click="mode = 'Forgot'" v-if="mode != 'Forgot'">
          Forgot Password?
        </button>
        <button class="page-account-register__switch" @click="mode = 'Login'" v-if="mode != 'Login'">
          Cancel
        </button>
      </div>
    </div>
  </AccountForms>
</template>

<script lang="ts" setup>
import { Ref, ref } from 'vue'

import AccountForms from '../account/AccountForms.vue'
import FormRegister from '../form/FormRegister.vue'
import FormForgot from '../form/FormForgot.vue'
import FormLogin from '../form/FormLogin.vue'

type Mode = 'Login' | 'Forgot' | 'Register'
const mode: Ref<Mode> = ref('Login')
</script>

<style lang="scss">
@import '~styles/base.scss';
/* @import '~styles/legacy.scss'; */

.page-account-register {
  &__switches {
    margin-top: 20px;
  }
  &__switch {
    @include animate-underline;
    @include type-xs;

    color: var(--color-midgrey);
    margin-right: auto;
  }
}
</style>

<template>
  <header :class="cn(['', `--${isHome && isOnLanding ? 'sticky' : 'default'}`])">
    <HeaderBanner />

    <div :class="cn('inner')">
      <ShopifyLinks
        :class="cn(['menu', 'menu--main'])"
        aria-label="Main navigation"
        :links="main.links"
      />

      <div :class="cn('logo')" :style="isHome ? { transform: `translateY(${logoTranslate}px)` } : {}">
        <a aria-label="Home" href="/"><SvgLogo /></a>
      </div>

      <ShopifyLinks
        :class="cn(['menu', 'menu--account'])"
        aria-label="Account navigation"
        :links="account.links"
      />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { LiquidLinkList } from '../../types/liquid'
import SvgLogo from '../svg/SvgLogo.vue'
import HeaderBanner from './HeaderBanner.vue'
import ShopifyLinks from '../shopify/link/ShopifyLinks.vue'
import { bem } from '../../utils/classnames'

const START_TOP = 100
const FIXED_TOP = 20

function useLogoTranslate() {
  const isOnLanding = ref(true)
  // The transform offset equals (current top position - FIXED_TOP)
  // At load: (200 - 20) = 180, and once scrolled far enough it reaches 0.
  const logoTranslate = ref(START_TOP - FIXED_TOP)

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const currentTop = Math.max(START_TOP - scrollTop, FIXED_TOP)
    logoTranslate.value = currentTop - FIXED_TOP
    isOnLanding.value = currentTop > FIXED_TOP
  }

  onMounted(() => window.addEventListener('scroll', onScroll))
  onUnmounted(() => window.removeEventListener('scroll', onScroll))

  return { isOnLanding, logoTranslate }
}

const { isOnLanding, logoTranslate } = useLogoTranslate()
const isHome = window.location.pathname === '/'
const cn = bem('header-desktop')

const props = defineProps<{
  main: LiquidLinkList
  account: LiquidLinkList
}>()
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  pointer-events: none;

  @include breakpoint('l') {
    display: none;
  }

  &__inner {
    padding: 16px var(--inset);
    display: grid;
    grid-template-columns: 1fr 150px 1fr;
    gap: 10px;
  }

  &__logo {
    display: block;
    pointer-events: auto;
  }

  &__menu {
    &--main {
      > * {
        pointer-events: auto;
        margin-right: auto;
      }
    }
    &--account {
      pointer-events: auto;
      margin-left: auto;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .shopify-link-dropdown {
    &__title {
      @include type-xs-uppercase;
    }
    &__links {
      .shopify-link {
        padding-left: 12px;
        color: var(--color-midgrey);
        position: relative;

        &--space {
          visibility: hidden;
        }

        &--heading {
          @include type-xxs-uppercase;
          color: var(--color-dark);
          margin-top: 16px;

          @include hover {
            &:after {
              display: none;
            }
          }
        }

        + *:not(.shopify-link--heading) {
          // margin-top: 2px;
        }

        &:first-child:not(.shopify-link--heading) {
          margin-top: 8px;
        }

        &:last-child {
          margin-bottom: 16px;
        }
        &:after {
          position: absolute;
          width: 3px;
          height: 3px;
          background: var(--color-dark);
          border-radius: 3px;
          content: '';
          left: 4px;
          top: calc(50% - 3px);

          opacity: 0;
          transform: translateY(5px);
          transition: opacity 0.2s ease-out, transform 0.1s ease-out;
        }

        &.shopify-link--active {
          color: var(--color-dark);
          &:after {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @include hover {
          color: var(--color-dark);
          &:after {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }

  .shopify-links {
    display: flex;
    flex-direction: column;
  }
}
</style>

<template>
  <span :class="cn(['', `--${isActive ? 'active' : 'inactive'}`])">
    <button @click="onClick" :class="cn('title')">
      {{ link.title }}
    </button>
    <TransitionHeight>
      <ShopifyLinks v-if="isActive" :class="cn('links')" :links="link.links" />
    </TransitionHeight>
  </span>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
// import { useWindowSize } from '@vueuse/core'
import { bem } from '../../../utils/classnames'

import { LiquidLink } from '../../../types/liquid'
import TransitionHeight from '../../transition/TransitionHeight.vue'
import ShopifyLinks from './ShopifyLinks.vue'
import { useDropdownInject } from '../../../context/useDropdownContext'

const cn = bem('shopify-link-dropdown')
// const { width } = useWindowSize()
const { toggle, current, set } = useDropdownInject()

const props = withDefaults(
  defineProps<{
    link: LiquidLink
    depth: number
  }>(),
  {
    depth: 0,
  }
)

onMounted(() => {
  if (props.link.child_active) set(props.link.title)
})

const isActive = computed(() => current?.value == props.link.title)

const onClick = () => {
  if (current?.value !== props.link.title) {
    set(props.link.title)
  } else {
    set('')
  }
}
</script>

<style lang="scss">
.shopify-link-dropdown {
  &__title {
  }
}
</style>

<template>
  <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.69534 3.2727L0 3.23008V3.86935H6.54363L4.1467 6.1139L4.67766 6.61111L8 3.5142L7.98483 3.5L7.46903 3.01699L4.67766 0.388885L4.1467 0.886095L6.69534 3.2727Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts" setup></script>
<style scoped>
svg {
  width: 12px;
  transform: translateY(1px);
}
</style>

<template>
  <AccountForms title="Create Account" class="page-account-login">
    <div>
      <FormLogin v-if="mode == 'Login'">
        If you have an account with us, sign in for a faster checkout process.
      </FormLogin>
      <FormForgot v-if="mode == 'Forgot'" />

      <div class="page-account-login__switch">
        <button @click="mode = 'Forgot'" v-if="mode == 'Login'">Forgot Password?</button>
        <button @click="mode = 'Login'" v-if="mode == 'Forgot'">Cancel</button>
      </div>
    </div>

    <section class="page-account-login__create">
      <div class="page-account-login__details">
        <h2 class="page-account-login__heading">New User</h2>
        <p class="page-account-login__content">
          By creating an account with our store, you will be able to move through the checkout process
          faster, store multiple shipping addresses, view and track your orders in your account and
          more.
        </p>
      </div>
      <div class="page-account-login__controls">
        <a href="/account/register" class="page-account-login__button">CREATE ACCOUNT</a>
      </div>
    </section>
  </AccountForms>
</template>

<script lang="ts" setup>
import FormLogin from '../form/FormLogin.vue'
import FormForgot from '../form/FormForgot.vue'
import AccountForms from '../account/AccountForms.vue'
import { Ref, ref } from 'vue'

type Mode = 'Login' | 'Forgot'

const mode: Ref<Mode> = ref('Login')
</script>

<style lang="scss">
@import '~styles/base.scss';
/* @import '~styles/legacy.scss'; */

.page-account-login {
  &__heading {
    @include uppercase;
    margin-bottom: 1em;
  }

  &__controls {
    padding-top: 60px;
    text-align: center;
  }

  &__button {
    @include button-underline;
  }

  &__switch {
    @include type-xs;
    color: var(--color-midgrey);
    margin-top: 20px;
    > * {
      @include animate-underline;
    }
  }
}
</style>

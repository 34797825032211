<template>
  <div :class="cn(['', `--${settings.display}`])">
    <div :class="cn('inner')">
      <CommonSlideshow mode="FADE" :slider-config="sliderConfig">
        <div :class="cn('slide')" v-for="(slide, index) in slides" :key="index">
          <img :class="cn('image')" :src="slide.image_url" :alt="slide.image_alt" />
        </div>
        <template #after>
          <HomeSectionSlideshowCaption :display="settings.display" :slides="slides" />
          <HomeSectionSlideshowControls :class="cn('controls')" />
        </template>
      </CommonSlideshow>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { bem } from '../../utils/classnames'
import CommonSlideshow from '../common/CommonSlideshow.vue'
import HomeSectionSlideshowCaption from './HomeSectionSlideshowCaption.vue'
import HomeSectionSlideshowControls from './HomeSectionSlideshowControls.vue'
import { EmblaOptionsType } from 'embla-carousel'

export interface HomeSectionSlideshowSlide {
  image_alt: string
  image_url: string
  link_title: string
  title: string
  subtitle: string
  link_url: string
}

const cn = bem('home-section-slideshow')

defineProps<{
  settings: { display: string }
  slides: HomeSectionSlideshowSlide[]
}>()

const sliderConfig = {
  align: 'center',
  containScroll: false,
  loop: true,
} as EmblaOptionsType
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-section-slideshow {
  @include type-xs;
  width: 100%;

  .common-slideshow {
    position: relative;
  }

  &__inner {
    position: relative;
    height: auto;
  }

  &__image {
    width: 100%;
    display: block;
  }

  &--default {
    display: flex;
    justify-items: center;
    padding-top: var(--home-section-padding-large);
    padding-bottom: var(--home-section-padding-large);

    @include breakpoint('l') {
      @include wrapper;
    }

    .home-section-slideshow {
      &__inner {
        position: relative;
        margin: 0 auto;
        @include breakpoint('l+') {
          width: columns(14);
        }
      }
    }
  }

  &--left,
  &--right {
    @include breakpoint('l+') {
      width: 50%;
      padding-top: var(--home-section-padding-default);
      padding-bottom: var(--home-section-padding-default);
    }
    @include breakpoint('l') {
      @include wrapper;
    }
  }

  &--left {
    .home-section-slideshow {
      @include breakpoint('l+') {
        &__inner {
          padding-left: calc(var(--inset) + #{columns(2, 0)});
          padding-right: calc(var(--inset) + #{columns(1)});
        }
      }
    }
    @include breakpoint('l') {
      padding-top: var(--home-section-padding-default);
      padding-bottom: 40px;
    }
  }

  &--right {
    .home-section-slideshow {
      @include breakpoint('l+') {
        &__inner {
          padding-right: calc(var(--inset) + #{columns(2, 0)});
          padding-left: calc(var(--inset) + #{columns(1)});
        }
      }
    }
    @include breakpoint('l') {
      padding-top: 40px;
      padding-bottom: var(--home-section-padding-default);
    }
  }
}
</style>

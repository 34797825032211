export const bem =
  (baseClass: string) =>
  (suffix?: string | string[]): string => {
    if (suffix === undefined) return baseClass
    if (typeof suffix === 'string') {
      return suffix === ''
        ? baseClass
        : suffix.startsWith('--')
        ? `${baseClass}${suffix}`
        : `${baseClass}__${suffix}`
    }
    return suffix
      .map((s) =>
        s === '' ? baseClass : s.startsWith('--') ? `${baseClass}${s}` : `${baseClass}__${s}`
      )
      .join(' ')
  }

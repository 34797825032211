<template>
  <div v-if="itemCount > 0" class="overlay-cart-controls">
    <div class="next">
      <a href="/checkout" class="button">Checkout</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCartInject } from '../../../context/useCartContext'

const { itemCount } = useCartInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart-controls {
  height: 60px;
  border-top: 1px solid var(--color-dark);

  .next {
    a {
      @include type-m;
      @include uppercase;
      padding: 20px 20px;
      text-align: center;
      display: block;

      &:focus,
      &:hover {
        color: var(--color-midgrey);
        text-decoration: none;
      }
    }
  }
}
</style>

<template>
  <CollectionContainer>
    <div class="collection-products">
      <div class="collection-products__inner" v-if="products.length > 0">
        <div class="collection__introduction">
          <div class="collection__subtitle">
            <span>Search</span>
            <span>Results: <span v-html="count"></span></span>
          </div>
          <CollectionSearchTitle class="collection__title" :terms="terms" />
        </div>
        <ProductCard v-for="product in products" :key="product.id" :product="product"></ProductCard>
      </div>
      <div class="collection-products__empty" v-else>Searching "{{ terms }}"...</div>
    </div>
  </CollectionContainer>
</template>

<script lang="ts" setup>
import { Ref, onMounted, ref } from 'vue'
import CollectionSearchTitle from '../collection/CollectionSearchTitle.vue'
import ProductCard from '../product/card/ProductCard.vue'
import { fetchSearchWithAllProducts } from '_library'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import CollectionContainer from '../collection/CollectionContainer.vue'

const props = defineProps<{
  terms: string
  count: number
}>()

const products: Ref<ProductFieldsFragment[]> = ref([])
const isDraft = (product: ProductFieldsFragment) => product.tags.find((tag) => tag == 'draft')
const isPreview = !!new URLSearchParams(window.location.search).get('preview')

onMounted(async () => {
  await fetchSearchWithAllProducts(props.terms, (results: ProductFieldsFragment[]) => {
    products.value = results.filter(
      (product) =>
        product.productType !== 'Admin' && (!isDraft(product) || (isDraft(product) && isPreview))
    )
  })
})
</script>

<style lang="scss">
@import '~styles/base';

.collection {
  &__introduction {
    flex-direction: column;
    position: relative;
    border: 1px solid;
    display: flex;
    padding: 30px;
    margin: 0;

    @include breakpoint('l') {
      grid-column: span 2;
      height: 450px;
      margin-top: 40px;

      &_hide_mobile {
        display: none;
      }
    }
  }

  &__subtitle {
    justify-content: space-between;
    padding-bottom: 10px;
    position: absolute;
    display: flex;
    bottom: 100%;
    left: 0;
    right: 0;
  }

  &__title {
    @include heading-l-serif;
    margin: auto 0;
    font-size: 42px;
    text-align: center;
  }
}
</style>
